import Project from "../Project";

import Thumbnail from "./dyson-01.jpg";
import Image2 from "./dyson-02.jpg";
import Image3 from "./dyson-03.jpg";
import Image4 from "./dyson-04.jpg";
import Image5 from "./dyson-05.jpg";

const content: Project = {
  id: "vortex-laundry",
  title: "Vortex Laundry",
  thumbnail: Thumbnail,
  periodFrom: "2020.03",
  periodTo: "2020.07",
  categories: [
    { title: "Product Design", participated: true },
  ],
  members: [
    ["Designer"],
    ["Designer"],
    ["Designer"],
    ["Designer"],
  ],
  myMemberIndex: 0,
  isPm: false,
  stacks: [
    { title: "Laser Cutting", participated: true },
    { title: "3D Printing", participated: true },
  ],
  materials: [
    { title: "Recycled Plastic Bucket", participated: true },
    { title: "Climbing Rope", participated: true },
    { title: "Propeller", participated: true },
    { title: "Acrylic Sheet", participated: true },
    { title: "3D Printed PLA", participated: true },
    { title: "Ceramic ball", participated: true },
    { title: "Spring", participated: true },
  ],
  others: [
    { type: "수상", content: "James Dyson Award 국내전 입상" },
  ],
  images: [
    Thumbnail,
    Image2,
    Image3,
    Image4,
    Image5,
  ],
};

export default content;
