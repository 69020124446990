import Project from "../Project";

import Thumbnail from "./Idea Factory.png";

const content: Project = {
  id: "idea-factory",
  title: "Idea Factory (Mobile)",
  thumbnail: Thumbnail,
  periodFrom: "2019.11",
  periodTo: "2020.05",
  periodComment: "초기 개발 기간 한정, 유지보수 및 관리 기간 제외",
  categories: [
    { title: "Front-End Development", participated: true },
    { title: "UI Design", participated: false },
  ],
  members: [
    ["Developer"],
    ["Designer"],
  ],
  myMemberIndex: 0,
  isPm: false,
  stacks: [
    { title: "HTML & CSS & Javascript", participated: true },
    { title: "Figma", participated: false },
  ],
  materials: null,
  link: "https://ideafactory.kaist.ac.kr/m/",
  images: [
    Thumbnail,
  ],
};

export default content;
