interface Exhibition {
  period: string,
  title: string,
  place: string,
  isTeam: boolean,
}

const exhibitions: Array<Exhibition> = [
  {
    period: "2024",
    title: "Snow Message (예정)",
    place: "주프랑스 한국문화원 파리 올림픽 및 패럴림픽 계기 특별 기획전 \"한국의 놀이\"",
    isTeam: true,
  },
  {
    period: "2024 - 현재",
    title: "Snow Globe (예정)",
    place: "대전신세계 Art & Science 넥스페리움",
    isTeam: true,
  },
  {
    period: "2024",
    title: "WonderScope",
    place: "나로우주센터 우주과학관 \"제14회 고흥우주항공축제\"",
    isTeam: true,
  },
  {
    period: "2023",
    title: "Sketchnote",
    place: "UIST 2023",
    isTeam: true,
  },
  {
    period: "2022",
    title: "WonderScope",
    place: "DDP \"Inventive Minds and Ideas: Living In The Future\"",
    isTeam: true,
  },
  {
    period: "2022",
    title: "WonderScope",
    place: "일산 KINTEX \"2022 대한민국 과학기술대전\"",
    isTeam: true,
  },
  {
    period: "2021 - 현재",
    title: "TransWall",
    place: "대전신세계 Art & Science 넥스페리움",
    isTeam: true,
  },
  {
    period: "2020 - 2021",
    title: "과학청진기",
    place: "지질박물관 특별전 \"그곳에 화산이 있었다\"",
    isTeam: true,
  },
  {
    period: "2019 - 2021",
    title: "In Memory of Our April",
    place: "광화문광장 세월호 추모공간 \"기억과 빛\"",
    isTeam: true,
  },
  {
    period: "2018",
    title: "루미랜드",
    place: "수성아트피아 특별전 \"Media Scenery\"",
    isTeam: true,
  },
];

export default exhibitions;
