import Project from "../Project";

import Image1 from "./Image_1.png";
import Image2 from "./Image_2.png";
import Image3 from "./Image_3.png";

const content: Project = {
  id: "sketchnote",
  title: "Sketchnote",
  thumbnail: Image1,
  periodFrom: "2021.03",
  periodTo: "2022.05",
  categories: [
    { title: "Design Research", participated: true },
  ],
  members: [
    ["Researcher"],
  ],
  myMemberIndex: 0,
  isPm: false,
  stacks: null,
  materials: null,
  others: [
    { type: "논문", content: "블록 기반 프로그래밍에서 문제 분해의 스케치 기반 시각화, 석사학위논문, 한국과학기술원, 대전" },
  ],
  images: [
    Image1,
    Image2,
    Image3,
  ],
};

export default content;
