interface Education {
  period: string,
  title: string,
}

const educations: Array<Education> = [
  {
    period: "2022 - 현재",
    title: "KAIST 박사과정 (산업디자인학과 전공)",
  },
  {
    period: "2020 - 2022",
    title: "KAIST 석사과정 (산업디자인학과 전공)",
  },
  {
    period: "2015 - 2020",
    title: "KAIST 학사과정 (산업디자인학과 전공 / 전산학부 복수전공)",
  },
  {
    period: "2013 - 2015",
    title: "경북과학고등학교",
  },
];

export default educations;
