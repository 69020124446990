import styled from 'styled-components';

import { BODY_MAX_WIDTH, NAVBAR_HEIGHT, PAGE_MARGIN } from '../constants';


const BodyDiv = styled.div`
  max-width: ${BODY_MAX_WIDTH + PAGE_MARGIN * 2}px;
  margin: 0 auto 50px auto;
  padding: 0 ${PAGE_MARGIN}px;

  position: relative;
  padding-top: ${NAVBAR_HEIGHT + 20}px;
  box-decoration-break: clone;
`;

type Props = {
    children: React.ReactNode,
}


const Body = (props: Props) => {
  return (
    <BodyDiv>
      { props.children }
    </BodyDiv>
  )
};

export default Body;
