import Project from "../Project";

import Thumbnail from "./april_temp.jpeg";

const content: Project = {
  id: "in-memory-of-our-april",
  title: "In Memory of Our April",
  thumbnail: Thumbnail,
  periodFrom: "2018.07",
  periodTo: "2019.04",
  categories: [
    { title: "Exhibition Design", participated: true },
    { title: "UI Design & Development", participated: true },
    { title: "System Development", participated: false },
  ],
  members: [
    ["PM", "Designer", "Developer"],
    ["Designer", "Developer"],
    ["Developer"],
    ["Developer"],
  ],
  myMemberIndex: 1,
  isPm: false,
  stacks: [
    { title: "OpenCV", participated: true },
  ],
  materials: null,
  others: [
    { type: "전시", content: "서울특별시 종로구 세종대로 172 광화문광장 기억과 빛", comment: "2021.07 전시 종료" },
  ],
};

export default content;
