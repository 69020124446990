import Project from "../Project";

import Thumbnail from "./image_1.jpg";
import Image2 from "./image_2.jpg";
import Image3 from "./image_3.jpg";

const content: Project = {
  id: "scienscope-2020",
  title: "Scienscope (2020)",
  thumbnail: Thumbnail,
  periodFrom: "2020.03",
  periodTo: "2020.12",
  categories: [
    { title: "Product Design", participated: true },
    { title: "Exhibition Design", participated: true },
    { title: "Design Research", participated: true },
  ],
  members: [
    ["PM", "Designer", "Researcher"],
    ["Designer", "Researcher"],
    ["Designer", "Researcher"],
    ["Designer", "Researcher"],
  ],
  myMemberIndex: 1,
  isPm: false,
  stacks: [
    { title: "3D Printing", participated: true },
    { title: "Audodesk Fusion 360", participated: true },
    { title: "Unity", participated: true },
    { title: "Arduino", participated: false },
    { title: "Android Studio", participated: false },
  ],
  materials: [
    { title: "3D Printed Resin (SLA)", participated: true },
    { title: "Spring", participated: true },
    { title: "EVA Sheet", participated: true },
    { title: "Distance Sensor", participated: false },
    { title: "Displacement Sensor", participated: false },
    { title: "RFID Sensor", participated: true },
    { title: "Bluetooth Chipset", participated: false },
  ],
  others: [
    { type: "전시", content: "대전광역시 유성구 과학로 124 한국지질자원연구원 지질박물관 특별전시실", comment: "2021.02 전시 종료" },
    { type: "논문", content: "SurfaceLens: near-surface spatial interaction with science museum exhibits, Digital Creativity, 33(1), 18-34" },
    { type: "디자인등록", content: "가상 이미지 뷰어, 등록번호 3011311890000" },
  ],
  images: [
    Thumbnail,
    Image2,
    Image3,
  ],
};

export default content;
