import Project from "../Project";

import Thumbnail from "./Kkok.jpeg";
import Image2 from "./Kkok_AppearanceMockup_3.jpg";
import Image3 from "./Kkok_AppearanceMockup_4.jpg";
import Image4 from "./Kkok_AppearanceMockup_1.jpg";
import Image5 from "./Kkok_AppearanceMockup_2.jpg";

const content: Project = {
  id: "kkok",
  title: "Kkok",
  thumbnail: Thumbnail,
  periodFrom: "2018.09",
  periodTo: "2018.12",
  categories: [
    { title: "Product Design", participated: true },
    { title: "Interaction Design", participated: true },
  ],
  members: [
    ["Designer"],
    ["Designer"],
    ["Designer"],
    ["Designer"],
  ],
  myMemberIndex: 0,
  isPm: false,
  stacks: [
    { title: "Laser Cutting", participated: true },
    { title: "Arduino", participated: true },
  ],
  materials: [
    { title: "Piezoelectric Sensor", participated: true },
    { title: "LED Array", participated: true },
    { title: "Speaker", participated: true },
    { title: "Bluetooth Module", participated: true },
    { title: "SD Card Module", participated: true },
    { title: "Arduino", participated: true },
    { title: "Acrylic Sheet & Pipe", participated: true },
  ],
  images: [
    Thumbnail,
    Image2,
    Image3,
    Image4,
    Image5,
  ],
};

export default content;
