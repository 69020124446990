import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { PAGE_MARGIN } from '../constants';

import photo from '../images/photo_2020.png';


const Title = styled.div`
  position: absolute;
  top: ${PAGE_MARGIN}px;
  left: ${PAGE_MARGIN}px;

  font-size: 34px;
  font-weight: bold;

  & > div:nth-child(2),
  & > div:nth-child(3) {
    color: #AAAAAA;
  }
`;

const Menu = styled.div`
  position: absolute;
  bottom: ${PAGE_MARGIN}px;
  left: ${PAGE_MARGIN}px;
  z-index: 1;
  @media (max-aspect-ratio: 3/5) {
    bottom: initial;
    top: ${PAGE_MARGIN + 330}px;
  }
`;

const MenuTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #555555;

  margin-bottom: 16px;

  @media not print {
    display: none;
  }
`

const MenuEntry = styled(Link)<{home?: boolean}>`
  display: block;

  font-size: 18px;
  font-weight: 400;
  color: #33333399;

  transition: color 0.3s;

  &:hover {
    color: #333333CC;
  }

  &:not(:last-child) {
    margin-bottom: 13px;
  }

  ${props => props.home && css`
    @media print {
      display: none;
    }
  `}
`;

const Visit = styled.div`
  position: absolute;
  top: ${PAGE_MARGIN}px;
  right: ${PAGE_MARGIN}px;

  font-size: 14px;

  & > a {
    font-weight: bold;
  }
`;


const Photo = styled.img`
  position: absolute;
  bottom: 0;
  right: calc(17vw - 9vh);
  width: calc(33vw + 33vh);
`;


const MainPage = () => {
  return (
    <>
      <Title>
        <div>
          HAN<br />
          SEUNG<br />
          HYEON
        </div>
        <div>
          -
        </div>
        <div>
          DESIGNER,<br />
          DEVELOPER,<br />
          RESEARCHER.
        </div>
      </Title>
      <Menu>
        <MenuTitle>목차</MenuTitle>
        <MenuEntry to="/cv">경력</MenuEntry>
        <MenuEntry to="/projects">프로젝트</MenuEntry>
      </Menu>
      <Visit>
        Visit <a href="https://han.sh">https://han.sh</a>
      </Visit>
      <Photo src={photo} />
    </>
  )
}

export default MainPage;
