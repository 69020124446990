import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";

import MainPage from './pages/MainPage';
import CvPage from './pages/CvPage';
import ProjectsPage from './pages/ProjectsPage';
import ProjectDetailPage from './pages/ProjectDetailPage';
import NotFoundPage from './pages/NotFoundPage';

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Routes>
      <Route index element={<MainPage />} />
      <Route path="cv" element={<CvPage />} />
      <Route path="cv/submission" element={<CvPage isSubmission />} />
      <Route path="projects" element={<ProjectsPage />} />
      <Route path="projects/:id" element={<ProjectDetailPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
