import Project from "../Project";

import Thumbnail from "./otl_dictionary_temp.png";

const content: Project = {
  id: "otl-plus-dictionary",
  title: "OTL Plus 과목사전",
  thumbnail: Thumbnail,
  periodFrom: "2015.11",
  periodTo: "2016.06",
  periodComment: "참여 기간 한정, 전체 프로젝트 기간: 2015.09 - 2016.06",
  categories: [
    { title: "UI Design", participated: false },
    { title: "Front-End Development", participated: true },
    { title: "Back-End Development", participated: true },
  ],
  members: [
    ["PM (former)", "Developer"],
    ["PM (latter)", "Developer"],
    ["Designer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
  ],
  myMemberIndex: 3,
  isPm: false,
  stacks: [
    { title: "Adobe Illustrator", participated: false },
    { title: "HTML & CSS & Javascript", participated: true },
    { title: "Django", participated: true },
    { title: "Apache HTTP Server", participated: false },
  ],
  materials: null,
  link: "https://otl.kaist.ac.kr",
};

export default content;
