import Project from "../Project";

import Thumbnail from "./otl_2021.png";

const content: Project = {
  id: "otl-plus-planner",
  title: "OTL Plus 졸업플래너",
  thumbnail: Thumbnail,
  periodFrom: "2021.09",
  periodTo: "2023.05",
  categories: [
    { title: "UI Design", participated: true },
    { title: "Front-End Development", participated: true },
    { title: "Back-End Development", participated: true },
  ],
  members: [
  ],
  myMemberIndex: 0,
  isPm: true,
  stacks: null,
  materials: null,
};

export default content;
