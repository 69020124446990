import Project from "../Project";

import Thumbnail from "./idms-1.png";
import Image2 from "./idms-2.png";
import Image3 from "./idms-3.png";

const content: Project = {
  id: "id-makerspace",
  title: "ID Makerspace",
  thumbnail: Thumbnail,
  periodFrom: "2021.01",
  periodTo: "2021.05",
  periodComment: "초기 개발 기간 한정, 유지보수 및 관리 기간 제외",
  categories: [
    { title: "Front-End Development", participated: true },
    { title: "UI Design", participated: false },
  ],
  members: [
    ["Developer"],
    ["Designer"],
  ],
  myMemberIndex: 0,
  isPm: false,
  stacks: [
    { title: "HTML & CSS & Javascript", participated: true },
    { title: "Figma", participated: false },
  ],
  materials: null,
  link: "https://idms.kaist.ac.kr",
  images: [
    Thumbnail,
    Image2,
    Image3,
  ],
};

export default content;
