import Project from "../Project";

import Thumbnail from "./Piggy Lamp - Image.jpg";

const content: Project = {
  id: "piggy-lamp",
  title: "Piggy Lamp",
  thumbnail: Thumbnail,
  periodFrom: "2018.03",
  periodTo: "2018.06",
  categories: [
    { title: "Product Design", participated: true },
    { title: "Interaction Design", participated: true },
  ],
  members: [
    ["Designer"],
  ],
  myMemberIndex: 0,
  isPm: false,
  stacks: [
    { title: "3D Printing", participated: true },
    { title: "Laser Cutting", participated: true },
    { title: "Vacuum Forming", participated: true },
    { title: "Arduino", participated: true },
  ],
  materials: [
    { title: "IR Proximity Sensor", participated: true },
    { title: "Color Sensor", participated: true },
    { title: "LED Strip", participated: true },
    { title: "Servo Motor", participated: true },
    { title: "Arduino", participated: true },
    { title: "Light Diffusion Polycarbonate Sheet", participated: true },
    { title: "3D Printed PLA", participated: true },
  ],
};

export default content;
