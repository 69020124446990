interface Other {
  period: string,
  title: string,
}

const others: Array<Other> = [
  {
    period: "2019",
    title: "2019 OPTeamus배 KAIST LOL 대회 준우승",
  },
];

export default others;
