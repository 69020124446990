import Project from "../Project";

import Thumbnail from "./2020_Poster_Page_3.png";
import Image1 from "./2020_Poster_Page_2.png";
import Image3 from "./2020_Poster_Page_4.png";

const content: Project = {
  id: "otl-plus-mobile-and-renewal",
  title: "OTL Plus 모바일 & 리뉴얼",
  thumbnail: Thumbnail,
  periodFrom: "2018.01",
  periodTo: "2020.01",
  categories: [
    { title: "UI Design", participated: true },
    { title: "Front-End Development", participated: true },
    { title: "Back-End Development", participated: true },
  ],
  members: [
    ["PM", "Designer", "Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
  ],
  myMemberIndex: 0,
  isPm: true,
  stacks: [
    { title: "Adobe XD", participated: true },
    { title: "React.js", participated: true },
    { title: "Redux", participated: true },
    { title: "Django", participated: true },
    { title: "Apache HTTP Server", participated: true },
    { title: "AWS", participated: false },
  ],
  materials: null,
  link: "https://otl.kaist.ac.kr",
  images: [
    Image1,
    Thumbnail,
    Image3,
  ],
};

export default content;
