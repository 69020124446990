import { Link } from 'react-router-dom';
import styled from "styled-components";

import Body from "../components/Body";
import NavBar from "../components/NavBar";

import Project from "../projects/Project";
import * as Projects from "../projects";

import AwardIcon from "../icons/award.svg";
import ExhibitionIcon from "../icons/exhibition.svg";
import LinkIcon from "../icons/link.svg";
import PatentIcon from "../icons/patent.svg";
import PmIcon from "../icons/pm.svg";
import PublicationIcon from "../icons/publication.svg";


const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 32px;
`;

const ProjectEntry = styled(Link)`
  page-break-inside: avoid;

  position: relative;

  height: 182px;

  border-radius: 6px;
  overflow: hidden;
  box-shadow: 1px 2px 5px #00000033;

  transition: background-color 0.3s, box-shadow 0.3s;

  display: flex;
  flex-direction: column;
  justify-content: end;

  &:hover {
    background-color: #88888815;
    box-shadow: 1px 2px 7px 2px #00000044;
  }
`;

const ProjectEntryImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  object-fit: cover;
`;

const ProjectEntryOverlay = styled.div`
  background-color: #F4F4F466;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);

  padding: 8px;

  color: #000000CC;
  text-align: left;
`;

const ProjectEntryOverlayTitle = styled.div`
  font-size: 15px;
  font-weight: bold;

  &:not(:first-child) {
    margin-top: 2px;
  }
`;

const ProjectEntryOverlayDetail = styled.div`
  font-size: 14px;
  
  margin-top: 2px;
`;

const ProjectEntryOverlayLabel = styled.div`
  font-size: 0;

  padding: 0 0 2px 0;

  > img {
    width: 12px;
    height: 12px;

    opacity: 0.4;

    &:not(:last-child) {
      margin-right: 2px;
    }
  }
`;


const ProjectsPage = () => {
  const sortedProjects = Object.values(Projects);
  sortedProjects.sort((p1, p2) => {
    if (p1.periodTo < p2.periodTo) {
      return 2;
    }
    else if (p1.periodTo > p2.periodTo) {
      return -2;
    }
    else if (p1.periodFrom < p2.periodFrom) {
      return 1;
    }
    return -1;
  })

  const getIcons = (project: Project) => {
    const icons = [];
    if (project.isPm) {
      icons.push(PmIcon);
    }
    if (project.link) {
      icons.push(LinkIcon);
    }
    if (project.others && project.others.some((o) => (o.type === "전시"))) {
      icons.push(ExhibitionIcon);
    }
    if (project.others && project.others.some((o) => (o.type === "논문"))) {
      icons.push(PublicationIcon);
    }
    if (project.others && project.others.some((o) => (o.type === "특허" || o.type === "디자인등록"))) {
      icons.push(PatentIcon);
    }
    if (project.others && project.others.some((o) => (o.type === "수상"))) {
      icons.push(AwardIcon);
    }
    return icons;
  }

  return (
    <>
      <NavBar pageName="프로젝트"/>
      <Body>
        <ProjectGrid>
          {
            sortedProjects.map((p) => {
              const icons = getIcons(p);

              return (
                <ProjectEntry to={`/projects/${p.id}`} key={p.id}>
                  <ProjectEntryImage src={p.thumbnail} alt={p.title} />
                  <ProjectEntryOverlay>
                    {
                      icons.length > 0 && (
                        <ProjectEntryOverlayLabel>
                          {
                            icons.map((i) => (
                              <img src={i} alt="" key={i} />
                            ))
                          }
                        </ProjectEntryOverlayLabel>
                      )
                    }
                    <ProjectEntryOverlayTitle>{ p.title }</ProjectEntryOverlayTitle>
                    <ProjectEntryOverlayDetail>{ p.periodFrom } - { p.periodTo }</ProjectEntryOverlayDetail>
                  </ProjectEntryOverlay>
                </ProjectEntry>
              );
            })
          }
        </ProjectGrid>
      </Body>
    </>
  )
}

export default ProjectsPage;
