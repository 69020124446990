import Project from "../Project";

import Thumbnail from "./Fiber Touch.jpeg";
import Image2 from "./Image_2.jpeg";
import Image3 from "./Image_1.jpeg";
import Image4 from "./Principles_1.png";
import Image5 from "./Principles_2.png";

const content: Project = {
  id: "fiber-touch",
  title: "Fiber Touch",
  thumbnail: Thumbnail,
  periodFrom: "2020.09",
  periodTo: "2020.12",
  categories: [
    { title: "Design Research", participated: true },
    { title: "Human-Computer Interaction", participated: true },
  ],
  members: [
    ["Researcher"],
  ],
  myMemberIndex: 0,
  isPm: false,
  stacks: [
    { title: "3D Printing", participated: true },
    { title: "Laser Cutting", participated: true },
  ],
  materials: [
    { title: "Optical Fiber", participated: true },
    { title: "Steel Wire", participated: true },
    { title: "3D Printed Resin (SLA)", participated: true },
    { title: "Conductive Paint", participated: true },
    { title: "Clear Tape", participated: true },
  ],
  images: [
    Thumbnail,
    Image2,
    Image3,
    Image4,
    Image5,
  ],
};

export default content;
