import Project from "../Project";

import Thumbnail from "./Thumbnail.jpg";

const content: Project = {
  id: "wonderscope-2022",
  title: "Wonderscope (2022)",
  thumbnail: Thumbnail,
  periodFrom: "2022.09",
  periodTo: "2022.12",
  periodComment: "참여 기간 한정, 전체 프로젝트 기간: 2022.01 - 2022.12",
  categories: [
    { title: "Product Design", participated: true },
    { title: "Exhibition Design", participated: true },
    { title: "Design Research", participated: true },
  ],
  members: [
    ["PM (former)", "Designer", "Researcher"],
    ["PM (latter)", "Designer", "Researcher"],
    ["Designer", "Researcher"],
    ["Designer", "Researcher"],
  ],
  myMemberIndex: 3,
  isPm: false,
  stacks:  null,
  // [
  //   { title: "3D Printing", participated: true },
  //   { title: "Audodesk Fusion 360", participated: true },
  //   { title: "Unity", participated: true },
  //   { title: "Arduino", participated: false },
  //   { title: "Android Studio", participated: false },
  // ],
  materials: null,
  // [
  //   { title: "3D Printed Resin (SLA)", participated: true },
  //   { title: "Spring", participated: true },
  //   { title: "EVA Sheet", participated: true },
  //   { title: "Distance Sensor", participated: false },
  //   { title: "Displacement Sensor", participated: false },
  //   { title: "RFID Sensor", participated: true },
  //   { title: "Bluetooth Chipset", participated: false },
  // ],
  others: [
    { type: "전시", content: "서울특별시 중구 을지로 281 DDP 디자인둘레길 B구간", comment: "2022.12 전시 종료" },
    { type: "전시", content: "경기도 고양시 일산서구 킨텍스로 217-60 KINTEX 제1전시장 1홀", comment: "2022.12 전시 종료" },
    { type: "전시", content: "대구광역시 달성군 유가읍 테크노대로6길 20 국립대구과학관 본관 1층", comment: "2022.11 전시 종료" },
    { type: "수상", content: "iF Design Award Winner" },
  ],
  images: [
    Thumbnail,
  ],
};

export default content;
