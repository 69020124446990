import { Navigate, useParams } from 'react-router-dom';
import styled, { css } from "styled-components";

import Body from "../components/Body";
import NavBar from "../components/NavBar";

import * as Projects from "../projects";
import projectRedirects from "../projects/redirects";


const ProjectTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 4px;
`;

const ProjectPeriod = styled.div`
  font-size: 14px;
  color: #888888;
  margin-bottom: 6px;
`;

const ProjectPeriodComment = styled.span`
  color: #88888866;
`;

const ProjectSummary = styled.div`
  font-size: 15px;
  color: #333333;
  line-height: 1.6;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

const ProjectDescription = styled.div`
  page-break-inside: avoid;  

  margin-top: 32px;
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

const ProjectDescriptionTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const ProjectDescriptionContent = styled.div`
`;

const ProjectDescriptionContentCategory = styled.div`
  &:not(:last-child) {
    margin-bottom: 6px;
  }

  & a {
    text-decoration: underline;
  }
`;

const ProjectDescriptionContentCategoryComment = styled.span`
  color: #33333366;
`;

const ProjectDescriptionContentCaption = styled.div`
  color: #33333366;
  font-size: 12px;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const ProjectDescriptionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 84px);
  gap: 6px;
`;

const ProjectDescriptionGridMember = styled.div<{me?: boolean}>`
  width: 84px;
  height: 84px;
  padding: 6px;
  background-color: ${props => props.me ? css`#DDDDDD` : css`#EEEEEE`};
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
  position: relative;

  ${props => props.me && css`
    ::after {
      content: '';
      display: block;
      
      position: absolute;
      right: 5px;
      top: 5px;

      width: 7px;
      height: 7px;
      border-radius: 50%;

      background-color: #AAAAAA;
    }
  `}
`;

const ProjectDescriptionGridMemberRole = styled.div`
  font-size: 13px;
  text-align: center;
`;

const ProjectImage = styled.div`
  page-break-inside: avoid;

  background-color: #F5F5F599;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

const ProjectImageImg = styled.img`
  max-width: 100%;
  @media not print {
    max-height: 85vh;
  }
  @media print {
    max-height: calc((100vh - 32px) / 2);
  }

  display: block;
  margin: 0 auto;
  
  border: 1px solid #F5F5F5;
`;

const NotParticipated = styled.span`
  color: #33333366;

  & sup {
    line-height: 0;
  }
`;


const ProjectDetailPage = (props: any) => {
    const params = useParams();

    if (params.id === undefined) {
      return null;
    }

    const targetProject = Object.values(Projects).find((p) => (p.id === params.id));

    if (!targetProject) {
      const redirect = projectRedirects[params.id];
      if (redirect === undefined) {
        return null;
      }
      else {
        return <Navigate replace to={`/projects/${redirect}`} />
      }
    }

    return (
      <>
        <NavBar pageName={`프로젝트 / ${ targetProject.title }`}/>
        <Body>
          <ProjectTitle>
            { targetProject.title }
          </ProjectTitle>
          <ProjectPeriod>
            { targetProject.periodFrom } - { targetProject.periodTo }
            {
              targetProject.periodComment && (
                <ProjectPeriodComment>{` (${targetProject.periodComment})`}</ProjectPeriodComment>
              )
            }
          </ProjectPeriod>

          {
            targetProject.summary && (
              <ProjectSummary>
                { targetProject.summary }
              </ProjectSummary>
            )
          }

          <ProjectDescription>
            <ProjectDescriptionTitle>
              구분
            </ProjectDescriptionTitle>
            <ProjectDescriptionContent>
              {
                targetProject.categories.map((c) => (
                  <ProjectDescriptionContentCategory key={c.title}>
                    {
                      c.participated
                        ? c.title
                        : <NotParticipated>{ c.title }<sup>*</sup></NotParticipated>
                    }
                  </ProjectDescriptionContentCategory>
                ))
              }
              {
                targetProject.categories.some((c) => !c.participated) && (
                  <ProjectDescriptionContentCaption>
                    *: Not mainly participated
                  </ProjectDescriptionContentCaption>
                )
              }
            </ProjectDescriptionContent>
          </ProjectDescription>

          <ProjectDescription>
            <ProjectDescriptionTitle>
              팀 구성
            </ProjectDescriptionTitle>
            <ProjectDescriptionGrid>
              {
                targetProject.members.map((m, i) => (
                  <ProjectDescriptionGridMember me={i===targetProject.myMemberIndex} key={i}>
                    {
                      m.map((r, j) => (
                        <ProjectDescriptionGridMemberRole key={j}>
                          { r }
                        </ProjectDescriptionGridMemberRole>
                      ))
                    }
                  </ProjectDescriptionGridMember>
                ))
              }
            </ProjectDescriptionGrid>
          </ProjectDescription>

          {
            targetProject.stacks && (
              <ProjectDescription>
                <ProjectDescriptionTitle>
                  기술 스택
                </ProjectDescriptionTitle>
                <ProjectDescriptionContent>
                  {
                    targetProject.stacks.map((s) => (
                      <ProjectDescriptionContentCategory key={s.title}>
                        {
                          s.participated
                            ? s.title
                            : <NotParticipated>{ s.title }<sup>*</sup></NotParticipated>
                        }
                      </ProjectDescriptionContentCategory>
                    ))
                  }
                  {
                    targetProject.stacks.some((s) => !s.participated) && (
                      <ProjectDescriptionContentCaption>
                        *: Not mainly participated
                      </ProjectDescriptionContentCaption>
                    )
                  }
                </ProjectDescriptionContent>
              </ProjectDescription>
            )
          }

          {
            targetProject.materials && (
              <ProjectDescription>
                <ProjectDescriptionTitle>
                  재료 및 부품
                </ProjectDescriptionTitle>
                <ProjectDescriptionContent>
                  {
                    targetProject.materials.map((m) => (
                      <ProjectDescriptionContentCategory key={m.title}>
                        {
                          m.participated
                            ? m.title
                            : <NotParticipated>{ m.title }<sup>*</sup></NotParticipated>
                        }
                      </ProjectDescriptionContentCategory>
                    ))
                  }
                  {
                    targetProject.materials.some((m) => !m.participated) && (
                      <ProjectDescriptionContentCaption>
                        *: Not mainly participated
                      </ProjectDescriptionContentCaption>
                    )
                  }
                </ProjectDescriptionContent>
              </ProjectDescription>
            )
          }

          {
            targetProject.link && (
              <ProjectDescription>
                <ProjectDescriptionTitle>
                  링크
                </ProjectDescriptionTitle>
                <ProjectDescriptionContent>
                  <ProjectDescriptionContentCategory>
                    <a href={targetProject.link} target="_blank" rel="noreferrer">
                      { targetProject.link }
                    </a>
                  </ProjectDescriptionContentCategory>
                </ProjectDescriptionContent>
              </ProjectDescription>
            )
          }

          {
            targetProject.others && (
              <ProjectDescription>
                <ProjectDescriptionTitle>
                  기타
                </ProjectDescriptionTitle>
                <ProjectDescriptionContent>
                  {
                    targetProject.others.map((o, i) => (
                      <ProjectDescriptionContentCategory key={i}>
                        { o.type } - { o.content } { o.comment && <ProjectDescriptionContentCategoryComment>({ o.comment })</ProjectDescriptionContentCategoryComment> }
                      </ProjectDescriptionContentCategory>
                    ))
                  }
                </ProjectDescriptionContent>
              </ProjectDescription>
            )
          }

          {
            targetProject.images?.map?.((i) => 
              <ProjectImage key={i}>
                <ProjectImageImg src={i} />
              </ProjectImage>
            )
          }

        </Body>
      </>
    )
  }
  
  export default ProjectDetailPage;