interface Award {
  period: string,
  title: string,
}

const awards: Array<Award> = [
  {
    period: "2023",
    title: "iF Design Award Winner",
  },
  {
    period: "2020",
    title: "James Dyson Award 국내전 입상",
  },
  {
    period: "2020",
    title: "KAIST 학과차석졸업",
  },
  {
    period: "2018",
    title: "학과우등장학금 (학과차석/성적우수)",
  },
  {
    period: "2017 - 2018",
    title: "구원(九元)장학재단 구원장학금",
  },
  {
    period: "2017",
    title: "학과우등장학금 (학과수석)",
  },
  {
    period: "2017 - 2019",
    title: "국가우수장학금 (이공계)",
  },
  {
    period: "2014",
    title: "국제정보올림피아드 교육생 대상자",
  },
  {
    period: "2014",
    title: "한국정보올림피아드 은상",
  },
  {
    period: "2014",
    title: "경상북도정보올림피아드 대상",
  },
];

export default awards;
