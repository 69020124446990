import Project from "../Project";

import Thumbnail from "./Brand Guideline.jpg";
import Image2 from "./Brand Guideline-15.jpg";
import Image3 from "./Brand Guideline-25.jpg";
import Image4 from "./Design Guideline-4.jpg";
import Image5 from "./Design Guideline-5.jpg";
import Image6 from "./Design Guideline-20.jpg";

const content: Project = {
  id: "sparcs-brand-and-design-guideline",
  title: "SPARCS Brand & Design Guideline",
  thumbnail: Thumbnail,
  periodFrom: "2018.03",
  periodTo: "2018.12",
  categories: [
    { title: "Brand Design", participated: true },
    { title: "UI Design", participated: true },
  ],
  members: [
    ["PM", "Designer"],
    ["Designer"],
    ["Designer"],
    ["Designer (former)"],
  ],
  myMemberIndex: 0,
  isPm: true,
  stacks: [
    { title: "Adobe Illustrator", participated: true },
    { title: "Adobe XD", participated: true },
  ],
  materials: null,
  images: [
    Thumbnail,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
  ],
};

export default content;
