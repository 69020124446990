import Project from "../Project";

import Thumbnail from "./transwall_temp.jpeg";

const content: Project = {
  id: "transwall-2021",
  title: "TransWall (2021)",
  thumbnail: Thumbnail,
  periodFrom: "2021.01",
  periodTo: "2021.08",
  periodComment: "참여 기간 한정, 전체 프로젝트 기간: 2020.11 - 2021.08",
  summary: [
    "TransWall은 양면 터치가 가능한 대형 투명 디스플레이입니다.",
    "이는 각자의 화면만을 바라보는 단절된 환경을 제공하는 기존 디스플레이들에서 벗어나 서로의 얼굴을 마주보며 함께 소통하는 새로운 인터랙션을 가능하게 합니다.",
    "넥스페리움 전시를 위해 2013년 개발된 기존 버전에서 화면 크기와 밝기를 증가시키고 외관을 다듬는 등 하드웨어를 전면 재설계하였으며, 전시의 테마와 대상 연령대를 고려한 컨텐츠를 새로 개발하였습니다.",
  ].join(" "),
  categories: [
    { title: "Exhibition Design", participated: true },
  ],
  members: [
    ["Designer"],
    ["Designer"],
    ["Designer"],
    ["Designer"],
  ],
  myMemberIndex: 0,
  isPm: false,
  stacks: [
    { title: "Python", participated: true },
    { title: "Unity", participated: true },
  ],
  materials: [
    { title: "Aluminum Profile", participated: true },
    { title: "Steel Plate", participated: false },
    { title: "Tempered Glass", participated: true },
    { title: "Short Throw Projector", participated: true },
    { title: "IR Touch Frame", participated: true },
    { title: "Surface Transducer", participated: false },
  ],
  others: [
    { type: "전시", content: "대전광역시 유성구 엑스포로 1 대전신세계 Art&Science 6층 넥스페리움" },
  ],
  images: [
    Thumbnail,
  ],
};

export default content;
