import styled, { css } from "styled-components";

import Body from "../components/Body";
import NavBar from "../components/NavBar";

import awards from "../data/awards";
import educations from "../data/educations";
import exhibitions from "../data/exhibitions";
import leaderships from "../data/leaderships";
import others from "../data/others";
import publications from "../data/publications";

import AwardIcon from "../icons/award.svg";


const CvEntry = styled.div`
  page-break-inside: avoid;  

  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

const CvEntryTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const CvEntryContent = styled.div<{noLightText?: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 6px;

  font-size: 14px;

  &:not(:last-child) {
    margin-bottom: 6px;
  }

  & > div:first-child {
    flex: 0 0 auto;

    width: 106px;
  }

  & > div:last-child {
    flex: 1 1 auto;

    color: ${props => !props.noLightText && css`#33333366`};

    & strong {
      color: #333333;
    }

    & sup {
      line-height: 0;
    }

    & img {
      width: 18px;
      height: 18px;
      vertical-align: middle;
      margin-top: -3px;
      line-height: 0;
    }
  }
`;

/*
const CvEntryCaption = styled.div`
  color: #33333366;
  font-size: 12px;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

`
*/


type Props = {
  isSubmission?: boolean;
};


const CvPage = ({ isSubmission }: Props) => {
  const highlightName = (authors: string) => {
    const nameRegex = /(Seung Hyeon Han|Seunghyeon Han|한승현)/gi;
    const slices = authors.split(nameRegex);
    return slices.map((s, i) => (
      nameRegex.test(s)
        ? <strong key={i}>{ s }</strong>
        : s
    ));
  }

  return (
    <>
      <NavBar pageName="경력"/>
      <Body>
        <CvEntry>
          <CvEntryTitle>EDUCATION</CvEntryTitle>
          {
            educations.map((e) => (
              <CvEntryContent key={e.title}>
                <div>
                  { e.period }
                </div>
                <div>
                  <strong>{ e.title }</strong>
                </div>
              </CvEntryContent>
            ))
          }
        </CvEntry>
        <CvEntry>
          <CvEntryTitle>AWARD &amp; SCHOLARSHIP</CvEntryTitle>
          {
            awards.map((a) => (
              <CvEntryContent key={a.title}>
                <div>
                  { a.period }
                </div>
                <div>
                  <strong>{ a.title }</strong>
                </div>
              </CvEntryContent>
            ))
          }
        </CvEntry>
        <CvEntry>
          <CvEntryTitle>LEADERSHIP</CvEntryTitle>
          {
            leaderships.map((l) => (
              <CvEntryContent key={l.title}>
                <div>
                  { l.period }
                </div>
                <div>
                  <strong>{ l.title }</strong>
                </div>
              </CvEntryContent>
            ))
          }
        </CvEntry>
        <CvEntry>
          <CvEntryTitle>EXHIBITION &amp; DEMONSTRATION</CvEntryTitle>
          {
            exhibitions.map((e) => (
              <CvEntryContent key={e.place}>
                <div>
                  { e.period }
                </div>
                <div>
                  <strong>{e.place} - &lt;{ e.title }&gt;</strong>
                </div>
              </CvEntryContent>
            ))
          }
        </CvEntry>
        <CvEntry>
          <CvEntryTitle>PUBLICATION</CvEntryTitle>
          {
            publications.map((p) => (
              <CvEntryContent noLightText key={p.title}>
                <div>
                  { p.period }
                </div>
                <div>
                  <strong>{ p.title }</strong><br />
                  { highlightName(p.authors) } / <strong>{ p.journal }</strong>, { p.page } { p.honors && <>/ <img src={AwardIcon} alt="" key={AwardIcon} /> <strong>{p.honors}</strong></> }
                </div>
              </CvEntryContent>
            ))
          }
        </CvEntry>
        {
          !isSubmission
            && (
              <CvEntry>
                <CvEntryTitle>OTHERS (NON-ACADEMICAL)</CvEntryTitle>
                {
                  others
                    .map((o) => (
                      <CvEntryContent key={o.title}>
                        <div>
                          { o.period }
                        </div>
                        <div>
                          <strong>{ o.title }</strong>
                        </div>
                      </CvEntryContent>
                    ))
                }
              </CvEntry>
            )
        }
      </Body>
    </>
  )
}

export default CvPage;
