import Project from "../Project";

import Thumbnail from "./OTL Poster 1.png";
import Image2 from "./OTL Poster 2.png";
import Image3 from "./OTL Poster 3.png";
import Image4 from "./OTL Poster 4.png";
import Image5 from "./Big Image 5.png";

const content: Project = {
  id: "otl-plus-timetable",
  title: "OTL Plus 모의시간표",
  thumbnail: Thumbnail,
  periodFrom: "2016.09",
  periodTo: "2017.12",
  categories: [
    { title: "UI Design", participated: true },
    { title: "Front-End Development", participated: true },
    { title: "Back-End Development", participated: true },
  ],
  members: [
    ["PM (latter)", "Designer", "Developer"],
    ["PM (former)", "Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
    ["Developer"],
  ],
  myMemberIndex: 0,
  isPm: true,
  stacks: [
    { title: "Adobe Illustrator", participated: true },
    { title: "HTML & CSS & Javascript", participated: true },
    { title: "Underscore.js", participated: true },
    { title: "Django", participated: true },
    { title: "Apache HTTP Server", participated: true },
  ],
  materials: null,
  link: "https://otl.kaist.ac.kr",
  images: [
    Thumbnail,
    Image2,
    Image3,
    Image4,
    Image5,
  ],
};

export default content;
