import Project from "../Project";

import Thumbnail from "./NeatHair-1.jpg";

const content: Project = {
  id: "neathair",
  title: "NeatHair",
  thumbnail: Thumbnail,
  periodFrom: "2018.09",
  periodTo: "2018.12",
  categories: [
    { title: "Algorithm Development", participated: true },
    { title: "Image Processing", participated: true },
  ],
  members: [
    ["Developer"],
    ["Developer"],
    ["Developer"],
  ],
  myMemberIndex: 0,
  isPm: false,
  stacks: [
    { title: "OpenCV", participated: true },
  ],
  materials: null,
};

export default content;
