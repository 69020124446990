import { Link } from "react-router-dom";
import styled from "styled-components";

import Body from "../components/Body";
import NavBar from "../components/NavBar";


const NotFoundMessage = styled.div`
  margin-top: calc(50vh - 100px);

  text-align: center;
  font-size: 20px;
  font-weight: bold;
`;

const NotFoundLink = styled.div`
  margin-top: 12px;

  text-align: center;
  color: #888888;
`;


const CvPage = () => {
  return (
    <>
      <NavBar pageName=""/>
      <Body>
        <NotFoundMessage>
          페이지를 찾을 수  없습니다
        </NotFoundMessage>
        <NotFoundLink>
          <Link to="/">
            홈으로 돌아가기
          </Link>
        </NotFoundLink>
      </Body>
    </>
  )
}

export default CvPage;
