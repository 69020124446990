import Project from "../Project";

import Thumbnail from "./portfolio_temp.png";

const content: Project = {
  id: "han-sh",
  title: "han.sh",
  thumbnail: Thumbnail,
  periodFrom: "2022.01",
  periodTo: "2022.03",
  summary: "Visit https://han.sh",
  categories: [
    { title: "UI Design", participated: true },
    { title: "Front-End Development", participated: true },
  ],
  members: [
    ["Myself"],
  ],
  myMemberIndex: 0,
  isPm: false,
  stacks: [
    { title: "Adobe XD", participated: true },
    { title: "React.js", participated: true },
  ],
  materials: null,
  link: "https://han.sh",
  images: [
    Thumbnail,
  ],
};

export default content;
